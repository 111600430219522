#modal{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000000d5;
    z-index: 1000000;
    pointer-events: all;
    display: none;
    justify-content: center;
    align-items: center;
}
#modal-form-container{
    background-color: #fff;
    padding: 3vw;
    height: 35vw;
    width: 80%;
    border-radius: .7vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}
#modal-form-container form{
    margin: 3vw;
    gap: 4vw;
}
#back-button{
    border: 0;
    background-color: transparent;
    place-self: start;
    position: absolute;
}
#textarea{
    height: 6vw;
}
#textarea textarea{
    font-size: 1vw;
}
#input-container input{
    font-size: 1vw;
}
#textarea label, #input-container div label{
    font-size: 1vw;
}
#modal-form-container h3{
    font-size: 1vw;
    font-weight: bold;
}
#prop-main-info-container{
    display: flex;
    flex-direction: row;
    width: 96vw;
    margin: auto;
    position: relative;
    top: 7vw;
    justify-content: space-between;
    gap: 2vw;
}
#main-info-img-container{
    display: flex;
    flex-direction: column;
    width: 60vw;
}
#main-info-img-set{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1vw;
}
#main-info-img-set img{
    object-fit: cover;
    height: 25vw;
    width: 60vw;
    border-radius: 1vw;
}
#carousel-content{
    display: flex;
    flex-direction: row;
    gap: 1vw;
    width: 100%;
    overflow-x: scroll;
}
#carousel-content img{
    height: 8vw;
    object-fit: cover;
}
#main-info-text-container{
    flex-grow: 1;
    padding: 2vw;
    border-radius: 1vw;
    box-shadow: 0 0 10px #ddd;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
#main-info-text-container h2, h3, p{
    margin: 0;
    color: #000;
    text-align: start;
    font-size: 1vw;
}
#modal-pop-button{
    border: 0;
    background-color: #FE6D36;
    height: 2.5vw;
    font-size: 1vw;
    color: #FFF;
    width: 10vw;
    border-radius: 2vw;
}
#main-info-text-container h2{
    width: 80%;
    font-size: 1.5vw;
}
#main-info-text-container h3{
    font-weight: bold;
    font-size: 1.2vw;
}
#main-info-data div{
    display: flex;
    flex-direction: row;
    gap: 1vw;
    align-items: center;
    width: fit-content;
    margin-right: 2vw;
    margin-bottom: .5vw;
}
#main-info-data div p {
    font-size: 1.1vw;
}
#main-info-data{
    display: flex;
    flex-wrap: wrap;
    border-radius: 1vw;
    box-shadow: 0 0 10px #ddd;
    margin-top: 1vw;
    padding: 1.2vw;
}
#map-container{
    width: fit-content;
    margin: auto;
    margin-top: 9vw;
}
#map-container img{
    object-fit: cover;
    width: 96vw;
    height: 40vw;
}
#reviews-cards-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 96vw;
    margin: auto;
    padding-top: 4vw;
}
#global-reviews-container{
    width: 84vw;
    margin: auto;
    box-shadow: 0 0 10px #ddd;
    border-radius: .7vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 3vw;
    padding: 6vw;
    background-color: #fff;
}
#global-rating{
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 3px solid #ddd;
    padding-bottom: 2vw;
    gap: 2vw;
}
#global-rating h3{
    font-size: 2vw;
    font-weight: bolder;
}
#reviews-form-container form{
    position: relative;
}
#reviews-form-container form input[type= submit]{
    background-color: #FE6D36;
    color: #fff;
    box-shadow: 1px 2px 5px #ddd;
    border: 0;
    height: 2em;
    width: 7em;
    border-radius: 3vw;
    position: absolute;
    right: 0;
    bottom: 1vw;
}
#reviews-form-container{
    margin-top: 2vw;
    width: 70%;
}
#reviews-form-container textarea{
    resize: none;
    height: 8vw;
    box-shadow: 0 0 10px #ddd;
    border: 0;
}
#reviews{
    position: relative;
    margin-top: 2vw;
    padding-bottom: 2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
#background{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}
#prop-navigate-button-container{
    width: 100%;
    margin: 2vw 0 2vw 0;
    display: flex;
    gap: 3vw;
    justify-content: center;
}
#prop-navigate-button-container a{
    text-decoration: none;
}
#prop-navigate-button-container button{
    height: 3vw;
    width: 11vw;
    border: 0;
    background-color: #FE6D36;
    color: #FFF;
    border-radius: 6vw;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5vw;
    font-size: 1vw;
    transition: all .4s ease;
}
#prop-navigate-button-container button:hover{
    transform: scale(1.06);
    box-shadow:0 8px 7px #ddd;
}
@media screen and (max-width: 800px) {
    #prop-navigate-button-container button{
        font-size: 1.5vw;
        width: 15vw;
        height: 4vw;
    }
    #prop-main-info-container{
        flex-direction: column-reverse;
    }
    #main-info-img-container{
        width: 100%;
    }
    #main-info-img-set img{
        width: 100%;
        height: 40vw;
    }
    #main-info-img-set div img{
        height: 15vw;
    }
    #main-info-text-container h2, h3, p{
        font-size: 1.8vw;
    }
    #modal-pop-button{
        height: 4vw;
        font-size: 1.8vw;
        width: 14vw;
    }
    #main-info-text-container h2{
        font-size: 3vw;
    }
    #main-info-text-container h3{
        font-size: 2.4vw;
    }
    #main-info-text-container{
        gap: 3vw;
        padding: 4vw;
    }
    #reviews{
        overflow-x: hidden;
    }
    #reviews-cards-container{
        min-width: fit-content;
        gap: 2vw;
    }
    #reviews-cards-scroll{
        overflow-x: auto;
        max-width: 100vw;
    }
    #reviews-cards-scroll::-webkit-scrollbar {
        display: none;
    }
    #reviews-form-container form input[type= submit]{
        font-size: 1.3vw;
        bottom: 1.3vw;
    }
}
@media screen and (max-width: 580px){
    #prop-navigate-button-container button{
        font-size: 3vw;
        width: 25vw;
        height: 7vw;
    }
    #modal-form-container{
        background-color: #fff;
        padding: 3vw;
        height: 180vw;
        justify-content: flex-end;
    }
    #modal-form-container h3{
        font-size: 3.5vw;
        text-align: center;
    }
    #back-button{
        top: 6vw;
        left: 4vw;
    }
    #main-info-text-container form button{
        font-size: 4vw;
    }
    #prop-main-info-container{
        top: 15vw;
    }
    #prop-main-info-container h2{
        font-size: 5vw;
        width: 98%;
    }
    #prop-main-info-container h3{
        font-size: 4vw;
        width: 98%;
    }
    #prop-main-info-container p{
        font-size: 4vw;
        width: 98%;
    }
    #modal-pop-button{
        font-size: 4vw;
        width: 80%;
        margin: auto;
        height: 8vw;
        border-radius: 5vw;
    }
    #main-info-img-set div{
        flex-direction: column;
        width: 100%;
    }
    #main-info-img-set div img{
        width: 100%;
        height: 40vw;
    }
    #main-info-img-container img{
        border-radius: 4vw;
    }
    #main-info-data{
        gap: 3vw;
        flex-direction: column;
    }
    #main-info-data div p{
        font-size: 4vw;
    }
    #map-container{
        margin-top: 20vw;
        height: 70vw;
    }
    #map-container img{
        height: 100%;
    }
    #global-rating-buttons-container{
        flex-direction: column;
        width: 90%;
        gap: 3vw;
    }
    #global-rating-buttons-container button{
        width: 100%;
        height: 7vw;
        border-radius: 5vw;
        font-size: 4vw;
    }
    #reviews-form-container{
        width: 90%;        
    }
    #reviews-form-container textarea{
        width: 100%;
        height: 30vw;
        border-radius: 3vw;
    }
    #reviews-form-container form input[type= submit]{
        font-size: 3vw;
        bottom: 3vw;
    }
}