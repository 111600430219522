#header-faq-container{
    position: relative;
    top: 7vw;
    width: 96vw;
    height: 30vw;
    margin: auto;
    border-radius: .7vw;
    box-shadow: 0 0 10px #ddd;
    display: flex;
    flex-direction: column;
    align-items: center;
}
#header-faq-img-container{
    position: absolute;
    width: 97%;
    height: 90%;
    align-self: center;
    bottom: 0;
    top: 0;
    overflow: hidden;
    border-radius: .7vw;
    z-index: -1;
    background-image: url("../static/media/lotus-design-n-print-wRzBarqn3hs-unsplash.png");
    background-position: right;
    background-size: cover;
    background-repeat: no-repeat;
}
#header-faq-img-container::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background:linear-gradient(to right, rgba(255, 109, 51, 0.629),rgba(255, 109, 51, 0.563),rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
    border-radius: .7vw;
}
#header-faq-text-container p, h2{
    margin: 0;
    text-align: center;
    color: #fff;
    margin: .5vw;
}
#header-faq-text-container h2{
    font-size: 3vw
}
#header-faq-text-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    margin-top: 6vw;
}
#faq-img-container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 20vw;
    gap: 2.5vw;
}
#faq-img-container img{
    width: 40%;
}
#faq-img-container > img:nth-child(2){
    width: 100%;
}
#faq-container{
    width: 85vw;
    background-color: #ffffff;
    border-radius: .7vw;
    box-shadow: 0 0 10px #ddd;
    margin: auto;
    padding: 2vw;
    position: relative;
    margin-bottom: 2vw;
    top: -4vw;
}
#faq{
    margin-top: 4vw;
}

@media screen and (max-width: 800px) {
    #faq-container{
        top: 0;
    }
}
@media screen and (max-width: 580px){
    #faq-img-container > img:nth-child(1), #faq-img-container > img:nth-child(3){
        display: none;
    }
    #header-faq-container{
        top: 15vw;
        height: 45vw;  
        border-radius: 5vw;      
    }
    #header-faq-img-container{
        border-radius: 5vw;
    }
    #header-faq-text-container{
        justify-content: center;
        align-items: center;
        width: 90%;
    }
    #header-faq-text-container p, h2{
        font-size: 3.5vw;
    }
    #header-faq-text-container h2{
        font-size: 5vw;
    }
    #faq-container{
        margin-top: 17vw;
        width: 92vw;
        border-radius: 5vw;
    }
    #faq-img-container{
        height: 40vw;
    }
}