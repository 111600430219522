#nav-bar-container{
    position: relative;
}
#mobile-nav-bar{
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: #fff;
    z-index: 100000;
    padding: 6vw;
    left: -200vw;
    transition: all .4s ease;
}
#nav-bar{
    display: flex;
    position: fixed;
    margin-top: 1vw;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 96vw;
    height: 5vw;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.099);
    border-radius: .7vw;
    z-index: 1000;
    left: 50%;
    transform: translate(-50%);
    font-size: 1vw;
}
#bars{
    display: none;
    pointer-events: all;
}
#nav-bar-logo{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2vw;
}
#nav-bar-logo img{
    width: 6vw;
}
#nav-bar-logo h4{
    font-style: italic;
    font-weight: normal;
}
#nav-bar-link-list ul{
    display: flex;
    flex-direction: row;
    gap: 2.5vw;
}
#nav-bar-link-list ul li{
    list-style-type: none;
    transition: all .4s ease;
}
#nav-bar-link-list ul li:hover{
    transform: scale(1.15);
}
#color-logo{
    display: none;
}
#nav-bar-link-list a{
    text-decoration: none;
    color: #ffffff;
}
@media screen and (max-width: 580px){
    #bars{
        display: inline-block;
    }
    #nav-bar-link-list{
        display: none;
    }
    #nav-bar-logo h4{
        display: none;
    }
    #nav-bar-logo img{
        width: 18vw;
    }
    #nav-bar{
        width: 76vw;
        height: 10vw;
        margin-top: 2vw;
        justify-content: space-between;
        padding: 0 10vw 0 10vw;
    }
    #mobile-color-logo{
        width: 40vw;
        margin-left: 5vw;
    }
    #mobile-back-button{
        display: block;
    }
    #mobile-nav-bar-link-list ul{
        display: flex;
        flex-direction: column;
        gap: 10vw;
        padding: 0;
        margin-left: 8vw;
    }
    #mobile-nav-bar-link-list ul li{
        list-style-type: none;
        color: #000;
    }
    #mobile-nav-bar-link-list a{
        text-decoration: none;
    }
}