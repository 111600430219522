.sparkle-container{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
#sparkle-subcontainer01{
    position: absolute;
    bottom: 0;
    left: 0;
    height: 40%;
    width: 10%;
    display: flex;
    flex-direction: column;
}
#sparkle-subcontainer02{
    position: absolute;
    top: 10%;
    right: 0;
    height: 40%;
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
}
#sparkle-subcontainer03{
    position: absolute;
    bottom: 0;
    right: 0;
    height: 40%;
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
}
#sparkle-subcontainer04{
    position: absolute;
    top: 14%;
    left: 0;
    height: 40%;
    width: 20%;
    display: flex;
    flex-direction: column;
}
#sparkle-subcontainer05{
    position: absolute;
    bottom: 0;
    left: 0;
    height: 20%;
    width: 50%;
    display: flex;
}
#sparkle-subcontainer06{
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}