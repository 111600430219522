.rated-review-card{
    display: flex;
    flex-direction: column;
    width: 21vw;
    height: 32vw;
    min-width: 200px;
    min-height: 30px;
    border-radius: 1vw;
    box-shadow: 0 0 10px #ddd;
    background-color: #fff;
}
.rated-review-card img{
    object-fit: cover;
    width: 100%;
    height: 20vw;
    border-radius: 1vw;
}
.rated-card-text-container{
    padding: 1vw;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.rated-card-text-header-container h4{
    margin: 0;
    font-size: 1.1vw;
}
.rated-card-text-header-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1vw;
}
.rated-card-text{
    display: block;
    width: 100%;
    height: 100%;
    overflow-wrap: break-word;
}
.rating-container{
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: .1vw;
}

@media screen and (max-width: 800px) {
    .rated-card-text-container p{
        font-size: 1.5vw;
    }
    .rated-card-text-header-container h4{
        font-size: 1.7vw;
    }
    .rated-review-card{
        min-width: 32vw;
        min-height: 42vw;
    }
}
@media screen and (max-width: 580px){
    .rated-review-card{
        min-width: 80vw;
        min-height: 90vw;
        border-radius: 4vw;
    }
    .rated-review-card img{
        border-top-left-radius: 4vw;
        border-top-right-radius: 4vw;
        height: 50vw;
    }
    .rated-card-text-header-container h4{
        font-size: 4vw;
    }
    .rated-card-text-container p{
        font-size: 3.5vw;
        padding-top: 2vw;
    }
    .rated-card-text-container{
        padding-top: 3vw;
    }
    #global-rating{
        flex-direction: column;
    }
    #global-reviews-container h3{
        font-size: 4vw;
    }
}